import React from 'react';
import Component3 from '../../components/component3/Component3';
import './container2.css';

const container2Data = [
  {
    title: 'Consectetur commodo veniam sunt.',
    text: 'Aliqua anim anim sint elit do eiusmod dolor mollit ea anim non deserunt enim. Occaecat aliquip aliqua proident sit amet ex minim incididunt consequat aliquip laboris occaecat.',
  },
  {
    title: 'Ex ut ipsum fugiat.',
    text: 'Ea quis voluptate proident sint duis reprehenderit. Amet adipisicing tempor ea eu occaecat proident amet ullamco exercitation pariatur ex deserunt aute.',
  },
  {
    title: 'Nostrud nisi cupidatat cupidatat.',
    text: 'Deserunt qui duis minim aute dolore ut sunt id amet. Id nulla labore labore consectetur aute elit veniam sit proident deserunt proident reprehenderit in do.',
  },
  {
    title: 'Esse magna duis ex ex.',
    text: 'Et est cillum incididunt incididunt irure consequat duis proident magna ipsum in aute. Pariatur sit laborum nostrud qui deserunt nostrud ex aute laboris incididunt Lorem anim minim eiusmod.',
  },
];

const Container2 = () => (
  <div className="container2 section__padding" id="container2">
    <div className="container2-heading">
      <h1>Cillum nisi in commodo Lorem ut excepteur id proident Lorem non voluptate sint elit qui.</h1>
      <p>Do in Lorem sint non {'>'}</p>
    </div>
    <div className="container2_container">
      {container2Data.map((item, index) => (
        <Component3 title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Container2;