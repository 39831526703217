import React from 'react';
import './header.css';

const Header = () => {
  return (
    <div className="header section__padding" id="home">
        <div className="header-content">
          <h1>Non reprehenderit consectetur<br />
          ut sunt ex.</h1>
          <p>Fugiat commodo aliquip ex laborum quis sint anim id.</p>
          
          <div className="header-content__input">
            <input type="email" placeholder="Culpa ipsum eu minim"></input>
            <button type="button">Sit eiusmod</button>
          </div>

          <div className="header-content__people">
            <p>Qui do ut duis reprehenderit.</p>
          </div>
        </div>
    </div>
  )
}

export default Header