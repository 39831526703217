import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.svg';
import './navbar.css';

const Menu = () => (
  <>
  <p><a href="#container1">Nostrud</a></p>
  <p><a href="#container2">Cupidatat</a></p>
  <p><a href="#container3">Velit</a></p>
  <p><a href="#blog">Officia</a></p>
  </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="navbar">
      <img src={logo} alt="logo" />
      <div className="navbar-links">
        <div className="navbar-links_container">
          <Menu />
        </div> 
      </div>
      <div className="navbar-sign">
        <p>Id consectetur</p>
        <button type="button">Pariatur</button>
      </div>
      <div className="navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#333" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#333" size={27} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
          <div className="navbar-menu_container scale-up-center">
            <div className="navbar-menu_container-links">
             <Menu />
             <div className="navbar-menu_container-links-sign">
              <p>Id consectetur</p>
              <button type="button">Pariatur</button>
             </div>
           </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar