import React from 'react';
import Component3 from '../../components/component3/Component3';
import './container1.css';

const Container1 = () => (
  <div className="container1 section__margin" id="container1">
    <div className="container1-heading">
      <h1>Enim et culpa duis do.</h1>
      <p><a href="#blog">Minim velit duis sun {'>'}</a></p>
    </div>
    <div className="container1-container">
      <Component3 title="Nostrud eiusmod" text="Commodo quis aute proident aute. Aute Lorem sit pariatur reprehenderit est eu." />
      <Component3 title="Adipisicing do" text="Exercitation amet ut incididunt in aliqua esse. Tempor in eu aliquip dolore." />
      <Component3 title="Cillum irure" text="Magna proident ipsum nostrud ipsum et eiusmod. Esse exercitation nostrud eiusmod ut qui sunt et tempor." />
    </div>
  </div>
);

export default Container1