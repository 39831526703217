import React from 'react';
import './component4.css';

const Component4 = () => (
   <div className="component4">
     <div className="component4-content">
       <p>Consequat cillum amet ullamco aute magna ut.</p>
       <h3>Dolor qui incididunt et qui. Anim elit veniam et adipisicing aliqua labore minim est nulla in aliqua irure veniam.</h3>
     </div>
     <div className="component4-btn">
       <button type="button">Aliquip nost</button>
     </div>
   </div>
  )


export default Component4