import React from 'react';
import Article from '../../components/article/Article';
import { product1, product2, product3 } from './imports.js';
import './blog.css';

const Blog = () => (
  <div className="blog section__padding" id="blog">
    <div className="blog-heading">
      <h1>Amet ex dolore exercitation, <br /> enim do duis deserunt.</h1>
    </div>
    <div className="blog-container">
      <div className="blog-container_groupA">
        <Article imgUrl={product1} date="27. februar, 2023" title="Consectetur quis labore ex pariatur proident cupidatat." text="Consectetur consectetur adipisicing dolore deserunt labore proident commodo adipisicing consequat sint sint ex. Eu excepteur velit aliquip officia. Cillum exercitation est et cillum in officia voluptate Lorem cupidatat." />
        <Article imgUrl={product2} date="12. januar, 2023" title="Fugiat culpa aliquip dolore incididunt." text="Occaecat est dolor deserunt commodo cupidatat voluptate dolore sunt id elit ex eu dolore. Laborum ullamco reprehenderit non labore nostrud do ullamco do velit occaecat commodo id ex." />
      </div>
      <div className="blog-container_groupB">
        <Article imgUrl={product3} date="16. november, 2022" title="Commodo proident voluptate eiusmod deserunt est adipisicing voluptate sint." text="In commodo id id fugiat ea cupidatat proident nisi ipsum deserunt fugiat pariatur pariatur. Laboris occaecat sit ex in Lorem deserunt id reprehenderit officia eiusmod elit. Sunt anim proident sit dolor aliquip occaecat occaecat veniam culpa ad in. Esse cupidatat esse commodo enim in. Ut laboris fugiat magna cupidatat nisi non amet aliquip eiusmod. Aute id consectetur eiusmod mollit." />
      </div>
    </div>
  </div>
);

export default Blog