import React from 'react';

import { Footer, Blog, Container3, Container2, Container1, Header } from './containers';
import { Component4, Brand, Navbar} from './components';
import './Demo.css';

const Demo = () => {
  return (
    <div className="Demo">
        <div className="Background">
          <Navbar />
          <Header />
        </div>
        <Brand />
        <Container1 />
        <Container2 />
        <Component4 /> 
        <Container3 />
        <Blog />
        <Footer />
    </div>
  )
}

export default Demo