import React from 'react';
import product4 from '../../assets/product4.jpg';
import './container3.css';

const Container3 = () => (
    <div className="container3 section__padding" id="container3">
      <div className="container3-image">
        <img src={product4} alt="imagealt" />
      </div>
      <div className="container3-content">
        <h1>Proident irure eu <br /> excepteur dolor non.</h1>
        <p>Tempor deserunt aliqua deserunt cupidatat aliqua adipisicing labore deserunt veniam exercitation eiusmod eiusmod est. Duis do aliqua nulla irure ad. Eu sint sit pariatur in minim aute amet cillum nisi nostrud reprehenderit incididunt exercitation. Ad veniam ex fugiat cillum Lorem nostrud reprehenderit ipsum ad dolor quis ut. Laborum minim est cupidatat do velit labore.</p>
        <h4>Et labore cillum irure ad {'>'}</h4>
      </div>
    </div>
  );

export default Container3