import React from 'react';
import { google, slack, figma, prestashop, wordpress } from './imports';
import './brand.css';

const Brand = () => (
  <div className="brand section__padding">
      <div>
        <img src={figma} alt="figma"></img>
      </div>
      <div>
        <img src={google} alt="google"></img>
      </div>
      <div>
        <img src={wordpress} alt="wordpress"></img>
      </div>
      <div>
        <img src={slack} alt="slack"></img>
      </div>
      <div>
        <img src={prestashop} alt="prestashop"></img>
      </div>
    </div>
  );

export default Brand