import React from 'react';
import logo from '../../assets/logo.svg';
import './footer.css';

const Footer = () => (
  <div className="footer section__padding">
    <div className="footer-heading">
      <h1>Commodo anim dolor amet mollit anim consequat tempor.</h1>
    </div>

    <div className="header-content__input-footer">
      <button type="button">Magna mollit</button>
    </div>

    <div className="footer-links">
      <div className="footer-links_logo">
        <img src={logo} alt="logo" />
        <p>Cillum labore do ullamco aliqua fugiat ex ea.</p>
      </div>
      <div className="footer-links_div">
        <h4>Officia mollit.</h4>
        <p>Anim minim</p>
        <p>Deserunt</p>
        <p>Velit culpa</p>
        <p>Proident</p>
      </div>
      <div className="footer-links_div">
        <h4>Culpa ullamco</h4>
        <p>In commodo</p>
        <p>Lea labore</p>
        <p>Dolore</p>
        <p>Reprehenderit</p>
      </div>
    </div>

    <div className="footer-copyright">
      <p>DEMO @ STUDIJO</p>
    </div>
  </div>
);

export default Footer;
